import {put} from "@redux-saga/core/effects";
import * as actions from "./paymentActions";
import axios from "axios";

export function* paymentLoadSaga(action){
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/payments?startDate=" + action.start + "&endDate=" + action.end,
            config
        );
        yield put(actions.paymentLoadFinish(response.data.result));

    } catch (error) {

    }
}