export const PROFILE_LOAD = 'PROFILE_LOAD';
export const PROFILE_LOAD_FINISH = 'PROFILE_LOAD_FINISH';
export const PROFILE_LOAD_ERROR = 'PROFILE_LOAD_ERROR';

export const PROFILE_UPDATE = 'PROFILE_UPDATE';

export const profileLoad = (token) => {
    return {
        type: PROFILE_LOAD,
        token: token
    }
}

export const profileLoadFinish = (payload) => {
    return {
        type: PROFILE_LOAD_FINISH,
        payload: payload
    }
}

export const profileLoadError = (error) => {
    return {
        type: PROFILE_LOAD_ERROR,
        error: error,
    }
}

export const profileUpdate = (data, token) => {
    return {
        type: PROFILE_UPDATE,
        token: token,
        data: data
    }
}



