import * as actionTypes from "./authActions";
import { updateObject } from "../../shared/utility";

const initialState = {
    token: null,
    token_type: null,
    expires_in: null,
    expireDate: null,
    error: null,
    errorMessage: null,
    loading: false,
    authRedirectPath: '/',
    menu: null,
    recoverStatus: null,
    actionType: null,
    account_active: false,
    paid_expiration: null,
    is_admin: null,
};

const authSuccess = (state, action) => {
    localStorage.setItem('token', action.payload.access_token);
    localStorage.setItem('token_type', action.payload.token_type);
    localStorage.setItem('expires_in', action.payload.expires_in);
    localStorage.setItem('account_active', action.payload.account_active);
    localStorage.setItem('paid_expiration', action.payload.paid_expiration);
    localStorage.setItem('is_admin', action.payload.is_admin);

    let expireDate = action.payload.expireDate ?
        action.payload.expireDate :
        new Date(new Date().getTime() + action.payload.expires_in * 1000);

    localStorage.setItem('expireDate', expireDate);
    localStorage.setItem('menu', JSON.stringify(action.payload.menu));


    return updateObject( state, {
        token: action.payload.access_token,
        token_type: action.payload.token_type,
        expires_in: action.payload.expires_in,
        expireDate: expireDate,
        error: false,
        errorMessage: null,
        loading: false,
        menu: action.payload.menu,
        actionType: action.type,
        account_active: action.payload.account_active,
        paid_expiration: action.payload.paid_expiration,
        is_admin:  action.payload.is_admin,
    } );
};

const authLogout = (state, action) => {
    return updateObject(
        state,
        {
            token: null,
            token_type: null,
            expires_in: null,
            menu: null,
            account_active: false,
            paid_expiration: null,
            is_admin: null,
        }
    );
};

const authFailed = (state, action) => {
    let message = "";
    if (action.payload.response.data.error.message) {
        message = action.payload.response.data.error.message;
    }
    return updateObject(
        state,
        {
            error: true,
            errorMessage: message
        });
};

const registerFailed = (state, action) => {

    let messages = [];
    if (action.payload.response.data.validation !== undefined) {
        Object.values(action.payload.response.data.validation).forEach(val => messages.push(val));
    } else if (action.payload.response.data.error.message !== undefined) {
        messages.push(action.payload.response.data.error.message);
    }

    return updateObject(
        state,
        {
            error: true,
            errorMessage: messages
        }
    );
}

const recoverPasswordFailed = (state, action) => {
    return updateObject(
        state,
        {
            recoverStatus: 'error',
        }
    );
}

const recoverPasswordSuccess = (state, action) => {
    return updateObject(
        state,
        {
            recoverStatus: 'success',
        }
    );
}

const resetPasswordFailed = (state, action) => {
    let messages = [];
    if (action.payload.response.data.validation !== undefined) {
        Object.values(action.payload.response.data.validation).forEach(val => messages.push(val));
    } else if (action.payload.response.data.error.message !== undefined) {
        messages.push(action.payload.response.data.error.message);
    }
    return updateObject(
        state,
        {
            error: true,
            errorMessage: messages
        }
    );
}

const resetPasswordSuccess = (state, action) => {
    return updateObject(
        state,
        {
            resetStatus: 'success',
        }
    );
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.LOGIN_SUCCESS: return authSuccess(state, action);
        case actionTypes.LOGIN_FAILED: return authFailed(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.REGISTER_FAILED: return registerFailed(state, action);
        case actionTypes.REGISTER_SUCCESS: return authSuccess(state, action);
        case actionTypes.RECOVER_PASSWORD_FAILED: return recoverPasswordFailed(state, action);
        case actionTypes.RECOVER_PASSWORD_SUCCESS: return recoverPasswordSuccess(state, action);
        case actionTypes.RESET_PASSWORD_FAILED: return resetPasswordFailed(state, action);
        case actionTypes.RESET_PASSWORD_SUCCESS: return resetPasswordSuccess(state, action);


        default:
            return state;
    }
};

export default reducer;