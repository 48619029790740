import {
    CloseSquare,
    Category,
    Graph,
    InfoSquare,
    User,
    ShieldFail,
    Wallet,
    TwoUsers,
    PaperPlus,
    Discount,
    People,
    Work,
    Notification,
    Activity,
} from "react-iconly";

const pages = [

    {
        id: "blank-page",
        title: "Blank Page",
        icon: <PaperPlus set="curved" className="remix-icon" />,
        navLink: "/pages/blank-page",
    },
    {
        id: "errors",
        title: "Error Pages",
        icon: <CloseSquare set="curved" className="remix-icon" />,
        children: [
            {
                id: "error-page",
                title: "404",
                navLink: "/pages/error-page",
            },
        ],
    },
    {
        id: "campaign",
        title: "Campaign",
        icon: <Category set="curved" className="remix-icon"/>,
        navLink: "/pages/campaign-page",
    },
    {
        id: "statistics",
        title: "Statistics",
        icon: <Graph set="curved" className="remix-icon"/>,
        navLink: "/pages/statistics",
    },
    {
        id: "logs",
        title: "Logs",
        icon:  <Graph set="curved" className="remix-icon" />,
        navLink: "/pages/logs",
    },
    {
        id: "request-statisitc",
        title: "Request Statistic",
        icon:  <Activity set="curved" className="remix-icon" />,
        navLink: "/pages/requestStatistic",
    },
    {
        id: "clients",
        title: "Clients",
        icon: <People set="curved" className="remix-icon" />,
        navLink: "/pages/clients",
    },
    {
        id: "payments",
        title: "Payments",
        icon:  <Wallet set="curved" className="remix-icon" />,
        navLink: "/pages/payments",
    },
    {
        id: "referrals",
        title: "Referrals",
        icon: <TwoUsers set="curved" className="remix-icon" />,
        navLink: "/pages/referrals",
    },
    {
        id: "referral-clients",
        title: 'Referral Clients',
        icon: <TwoUsers set="curved" className="remix-icon" />,
        navLink: "/pages/referralClients",
    },
    {
        id: "blacklist",
        title: "Black List",
        icon: <ShieldFail set="curved" className="remix-icon" />,
        navLink: "/pages/blacklist",
    },
    {
        id: "promo",
        title: "Promo",
        icon: <Discount set="curved" className="remix-icon" />,
        navLink: "/pages/promo",
    },
    {
        id: "client-blacklist",
        title: "BlackList",
        icon: <ShieldFail set="curved" className="remix-icon" />,
        navLink: "/pages/clientBlacklist",
    },
    {
        id: "tariff",
        title: "Tariff",
        icon: <Work set="curved" className="remix-icon" />,
        navLink: "/pages/tariff",
    },
    {
        id: "integration",
        title: "Integration",
        icon: <InfoSquare set="curved" className="remix-icon"/>,
        navLink: "/pages/integration",
    },
    {
        id: "tariff",
        title: "Tariffs",
        icon:  <Wallet set="curved" className="remix-icon" />,
        navLink: "/pages/tariffs",
    },
    {
        id: "notifications",
        title: "Notifications",
        icon: <Notification set="curved" className="remix-icon" />,
        navLink: "/pages/notifications",
    },
    {
        id: "faq",
        title: "FAQ",
        icon: <InfoSquare set="curved" className="remix-icon" />,
        navLink: "/pages/faq",
    },
    {
        id: "profile",
        title: "Profile",
        icon: <User set="curved" className="remix-icon" />,
        navLink: "/pages/profile",
    },
    {
        id: "team-users",
        title: "Team Users",
        icon: <People set="curved" className="remix-icon" />,
        navLink: "/pages/teamUsers",
    },
];

export default pages