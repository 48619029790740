import React, { useRef, useState } from "react";

import { useSelector } from "react-redux";

import { Layout, Button, Row, Col } from "antd";
import { RiMenuFill, RiTimeLine } from "react-icons/ri";

import HeaderUser from "./HeaderUser";
import HeaderNotifications from "./HeaderNotifications";
import HeaderLanguge from "./HeaderLanguage";
import HeaderMessage from "./HeaderMessage";
import { withTranslation } from "react-i18next";


const { Header } = Layout;

function MenuHeader(props) {
  const { setVisible, t } = props;

  const [searchHeader, setSearchHeader] = useState(false);
  const [searchActive, setSearchActive] = useState(false);

  // Redux
  const customise = useSelector(state => state.customise);
  const profile = useSelector(state => state.profile.profile);

  // Focus
  const inputFocusRef = useRef(null);
  const inputFocusProp = {
    ref: inputFocusRef,
  };

  // Search Active
  setTimeout(() => setSearchActive(searchHeader), 100);

  const searchClick = () => {
    setSearchHeader(true)

    setTimeout(() => {
      inputFocusRef.current.focus({
        cursor: 'start',
      });
    }, 200);
  }

  // Mobile Sidebar
  const showDrawer = () => {
    setVisible(true);
    setSearchHeader(false);
  };

  // Children
  const headerChildren = (profile) => {
    let accountInfo = null;

    if (profile && profile.paidExpiration != null) {
      let currentDate = new Date();
      let expiredDate = new Date(profile.paidExpiration);
      let expiredText = '';
      let headerText = t("Paid for") + ' ' + profile.paidExpiration;
      if (profile.isActive === false) {
        headerText = <span className="title-not-active">{t("Activation is available after payment.")}</span>;
      } else {
        if (currentDate > expiredDate) {
          expiredText = <span className="title-not-active hp-ml-10">{t("Your payment plan already expired.")}</span>;
        }
      }
      accountInfo = <>
        <RiTimeLine size={18} className="remix-icon hp-text-color-primary-1 icon-timeline" />
        {headerText} {expiredText}
      </>
    }

    return (
      <Row
        className="hp-w-100 hp-position-relative"
        align="middle"
        justify="space-between"
      >
        <Col className="hp-mobile-sidebar-button hp-mr-24">
          <Button
            className="hp-mobile-sidebar-button"
            type="text"
            onClick={showDrawer}
            icon={
              <RiMenuFill
                size={36}
                className="remix-icon hp-text-color-black-60 hp-text-color-dark-30"
              />
            }
          />
        </Col>

        <Col xs={0} md={8}>
          <Row align="middle" style={{"fontSize": "0.75rem"}}>
          {accountInfo}
          </Row>
        </Col>

        <Col>
          <Row align="middle">

            <HeaderNotifications />

            <HeaderLanguge lang="en" />

            <HeaderMessage />

            <HeaderUser />
          </Row>
        </Col>
      </Row>
    )
  }

  return (
    <Header>
      <Row justify="center" className="hp-w-100">
        {
          customise.contentWidth == "full" && (
            <Col span={24}>
              {headerChildren(profile)}
            </Col>
          )
        }

        {
          customise.contentWidth == "boxed" && (
            <Col xxl={20} xl={22} span={24}>
              {headerChildren()}
            </Col>
          )
        }
      </Row>
    </Header>
  );
};
export default withTranslation()(MenuHeader);