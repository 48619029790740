import { put } from "redux-saga/effects";

import axios from "axios";
import * as actions from "./profileActions";
import * as systemActions from "../system/systemActions";

export function* loadProfileSaga(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }

    try {
        const response = yield axios.get(
            "/v1/profile",
            config
        );
        yield put(actions.profileLoadFinish(response.data.result));

    } catch (error) {
        yield put(actions.profileLoadError(error));
    }
}

export function* updateProfileSaga(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }

    try {
        const response = yield axios.post(
            "/v1/profile",
            action.data,
            config
        );
        if (response.data.result.success) {
            yield put(systemActions.systemNotification(response.data.result.message));
        } else {

        }

    } catch (error) {

    }
}