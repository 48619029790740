import * as actionTypes from "./paymentActions";
import { updateObject } from "../../shared/utility";


const initialState = {
    startDate: null,
    endDate: null,
    payments: null
};


const paymentLoadFinished = (state, action) => {
    return updateObject(
        state,
        action.payload
    );
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.PAYMENT_LOAD_FINISH: return paymentLoadFinished(state, action);
        default:
            return state;
    }
};

export default reducer;