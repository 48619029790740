import React, { Suspense } from "react";

import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { Provider } from "react-redux";
import store from './redux/store'

import "./assets/icons/remixicon.css";
import "./assets/less/yoda-theme.less";
import "./i18nextConf";

import App from "./App";

Sentry.init({
    dsn: "https://1d1126974e504f7b81be83a9e3ea17a2@o1130011.ingest.sentry.io/6455253",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Suspense fallback="loading">
    <Provider store={store}>
      <BrowserRouter >
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);