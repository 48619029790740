import { takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";

import * as profileActions from "./profile/profileActions";
import { loadProfileSaga, updateProfileSaga} from "./profile/profileSaga";

import * as authActionTypes from "./auth/authActions"
import * as authSagas from "./auth/authSaga";

import * as promoActions from "./promo/promoActions";
import {addPromo, deletePromoSaga, loadPromoSaga} from "./promo/promoSaga";

import * as referralActions from "./referral/referralActions";
import * as referralSaga from "./referral/referralSaga";

import * as tariffActions from "./tariff/tariffActions";
import * as tariffSaga from "./tariff/tariffSaga";

import * as paymentActions from "./payments/paymentActions";
import * as paymentSaga from "./payments/paymentSaga";

import * as clientActions from "./clients/clientActions";
import * as clientSaga from "./clients/clientSaga";

import * as integrationActions from "./integration/integrationActions";
import * as integrationSaga from "./integration/integrationSaga";

import * as logsActions from "./logs/logsActions";
import * as logsSaga from "./logs/logsSaga";

import * as campaignActions from "./campaign/campaignActions";
import * as campaignSaga from "./campaign/campaignSaga";

import * as blackListActions from "./blacklist/blackListActions";
import * as blackListSaga from "./blacklist/blackListSaga";

import * as referralClientsActions from "./referralClients/referralClientsActions";
import * as referralClientsSaga from "./referralClients/referralClientsSaga";

import * as clientBlackListActions from "./clientBlacklist/clientBlackListActions";
import * as clientBlackListSaga from "./clientBlacklist/clientBlackListSaga";

import * as notifActions from "./notifications/notificationsActions";
import * as notifSaga from "./notifications/notificationsSaga";

import * as teamUsersActions from "./teamUsers/teamUsersActions";
import * as teamUsersSaga from "./teamUsers/teamUsersSaga";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export function* watchProfileSaga() {
    yield takeLatest(profileActions.PROFILE_LOAD, loadProfileSaga);
    yield takeEvery(profileActions.PROFILE_UPDATE, updateProfileSaga);
}

export function* watchPromoSaga() {
    yield takeLatest(promoActions.PROMO_LOAD, loadPromoSaga);
    yield takeLatest(promoActions.PROMO_DELETE, deletePromoSaga);
    yield takeLatest(promoActions.PROMO_ADD, addPromo);
}

export function* watchReferralSaga() {
    yield takeLatest(referralActions.REFERRAL_LOAD, referralSaga.referralLoadSaga);
    yield takeLatest(referralActions.ROYALTY_ADD, referralSaga.addRoyalty);
}

export function* watchTariffSaga() {
    yield takeLatest(tariffActions.TARIFF_LOAD, tariffSaga.tariffLoadSaga);
    yield takeLatest(tariffActions.TARIFF_ADD, tariffSaga.addTariff);
    yield takeLatest(tariffActions.TARIFF_DELETE, tariffSaga.deleteTariffSaga);

    yield takeLatest(tariffActions.TARIFF_CHANGE, tariffSaga.changeTariffSaga);
}

export function* watchCampaignSaga() {
    yield takeLatest(campaignActions.CAMPAIGN_LOAD, campaignSaga.campaignLoadSaga);
    yield takeLatest(campaignActions.DOWNLOAD_WP_INTEGRATION, campaignSaga.downloadWpIntegration);
    yield takeLatest(campaignActions.DOWNLOAD_INTEGRATION, campaignSaga.downloadIntegration);

    yield takeLatest(campaignActions.CAMPAIGN_EDIT_DATA, campaignSaga.campaignEditData);
    yield takeLatest(campaignActions.CAMPAIGN_SAVE_DATA, campaignSaga.campaignSaveData);
    yield takeLatest(campaignActions.CAMPAIGN_DELETE, campaignSaga.campaignDelete);
    yield takeLatest(campaignActions.CAMPAIGN_COPY, campaignSaga.campaignCopy);
    yield takeLatest(campaignActions.CAMPAIGN_NAME_CHANGE, campaignSaga.campaignNameChange);
}

export function* watchClientSaga() {
    yield takeLatest(clientActions.CLIENTS_LOAD, clientSaga.clientsLoadSaga);
    yield takeEvery(clientActions.CLIENTS_SET_ACTIVATION, clientSaga.clientsSetActivation);
    yield takeEvery(clientActions.CLIENT_ADD_PAYMENT, clientSaga.clientAddPayment);
    yield takeEvery(clientActions.CLIENT_ADD_NOTES, clientSaga.clientAddNotes);
}

export function* watchBlackListSaga() {
    yield takeLatest(blackListActions.BLACKLIST_LOAD, blackListSaga.blackListLoadSaga);
    yield takeLatest(blackListActions.BLACKLIST_ADD, blackListSaga.addBlackList);
}

export function* watchClientBlackListSaga() {
    yield takeLatest(clientBlackListActions.CLIENT_BLACKLIST_LOAD, clientBlackListSaga.clientBlackListLoadSaga);
    yield takeLatest(clientBlackListActions.CLIENT_BLACKLIST_ADD, clientBlackListSaga.addClientBlackList);
}

export function* watchReferralClientsSaga() {
    yield takeLatest(referralClientsActions.REFERRAL_CLIENTS_LOAD, referralClientsSaga.referralClientsLoadSaga);
}

export function* watchIntegrationSaga() {
    yield takeLatest(integrationActions.INTEGRATION_LOAD, integrationSaga.loadIntegrationSaga);
    yield takeEvery(integrationActions.INTEGRATION_EDIT, integrationSaga.integrationEditSaga);
}

export function* watchLogSaga() {
    yield takeLatest(logsActions.GET_CLIENT_LIST, logsSaga.getClienListSaga);
    yield takeLatest(logsActions.GET_CLIENT_COMPANIES, logsSaga.getClienCompaniesSaga);
    yield takeLatest(logsActions.GET_COMPANY_LOGS, logsSaga.getCompanyLogsSaga);

    yield takeLatest(logsActions.GET_USER_COMPANIES, logsSaga.getUserCompaniesSaga);
    yield takeLatest(logsActions.GET_USER_LOGS, logsSaga.getUserLogsSaga);
    yield takeLatest(logsActions.GET_USER_COMPANY_FILTER, logsSaga.getUserCompanyFilterSaga);

    yield takeLatest(logsActions.GET_COMPANY_TOTALS, logsSaga.loadCompanyTotalsSaga);
    yield takeLatest(logsActions.GET_COMPANY_DAILY, logsSaga.loadCompanyDailysSaga);
    yield takeLatest(logsActions.GET_COMPANY_PLATFORM, logsSaga.loadCompanyPlatformsSaga);

    yield takeLatest(logsActions.GET_REQUEST_STATISTIC, logsSaga.loadRequestStatisticSaga);
    yield takeLatest(logsActions.GET_TOP_REQUEST_STATISTIC, logsSaga.loadTopRequestStatisticSaga);
}

export function* watchPaymentSaga() {
    yield takeLatest(paymentActions.PAYMENT_LOAD, paymentSaga.paymentLoadSaga);
}

export function* watchAuthSaga() {
    yield takeEvery(authActionTypes.LOGIN, authSagas.authLoginSaga);
    yield takeEvery(authActionTypes.REGISTER, authSagas.authRegisterSaga);
    yield takeEvery(authActionTypes.AUTH_CHECK_STATE, authSagas.authCheckStateSaga);
    yield takeEvery(authActionTypes.AUTH_INITIATE_LOGOUT, authSagas.logoutSaga);
    yield takeEvery(authActionTypes.CHECK_AUTH_TIMEOUT, authSagas.checkAuthTimeoutSaga);
    yield takeEvery(authActionTypes.RECOVER_PASSWORD, authSagas.authRecoverPasswordSaga);
    yield takeEvery(authActionTypes.RESET_PASSWORD, authSagas.authResetPasswordSaga);
}

export function* watchNotificationSaga() {
    yield takeLatest(notifActions.GET_NOTIFICATIONS, notifSaga.getNotificationsSaga);
    yield takeEvery(notifActions.CLEAR_NOTIFICATIONS, notifSaga.clearNotificationsSaga);
    yield takeLatest(notifActions.ADD_NOTIFICATION, notifSaga.addNotificationsSaga);
}

export function* watchTeamUsersSaga() {
    yield takeLatest(teamUsersActions.TEAM_USERS_LOAD, teamUsersSaga.teamUsersLoadSaga);
    yield takeEvery(teamUsersActions.TEAM_USER_ADD, teamUsersSaga.addTeamUserSaga);
    yield takeEvery(teamUsersActions.TEAM_USER_UPDATE, teamUsersSaga.updateTeamUserSaga);
    yield takeEvery(teamUsersActions.TEAM_USER_DELETE, teamUsersSaga.deleteTeamUserSaga);
}
