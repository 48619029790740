import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import {availableLanguages, languageComponents} from "./configs/languages";

import translationEN from "./assets/locale/en/translation";
import translationRU from "./assets/locale/ru/translation";
import translationUA from "./assets/locale/ua/translation";

const fallbackLng = ['ru'];

const resources = {
    en: {
        translation: translationEN
    },
    ru: {
        translation: translationRU
    },
    ua: {
        translation: translationUA
    }
};

i18n.on('languageChanged', function(lng) {
    if (lng.length > 2) {
        lng = lng.slice(0,2);
        i18n.changeLanguage(lng);
    }

    if (lng === 'uk') {
        lng = 'ua';
        i18n.changeLanguage(lng);
    }

});

i18n
    .use(Backend) // load translations using http (default                                               public/assets/locals/en/translations)
    .use(LanguageDetector) // detect user language
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        resources,
        fallbackLng, // fallback language is english.

        detection: {
            checkWhitelist: true, // options for language detection
        },

        debug: true,

        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false, // no need for react. it escapes by default
        },
    });



export default i18n;