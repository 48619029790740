import { Col, Layout, Row } from "antd";
const { Footer } = Layout;

export default function MenuFooter() {
  return (
    <Footer className="hp-bg-color-black-10 hp-bg-color-dark-100">
      <Row align="middle" justify="space-between">
        <Col md={24} span={24}>
          <p className="hp-badge-text hp-mb-0 hp-text-color-dark-30 hp-text-center  ">
            COPYRIGHT ©2022 Palladium, All rights Reserved
          </p>
        </Col>
      </Row>
    </Footer>
  );
};