import {delay, put} from "@redux-saga/core/effects";
import * as actions from "./notificationsActions";
import axios from "axios";
import * as systemActions from "../system/systemActions";

var notifAxios = axios.create();
notifAxios.defaults.baseURL = process.env.REACT_APP_API_URL;


export function* getNotificationsSaga(action){
    if (!action.token) {
        return;
    }
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield notifAxios.get(
            "/v1/notifications",
            config
        );

        if (response && response.data && response.data.result) {
            yield put(actions.getNotificationsDone(response.data.result));
            yield delay(30 * 1000);
            yield put(actions.getNotifications(action.token));
        }

    } catch (error) {

    }
}

export function* addNotificationsSaga(action){
    if (!action.token) {
        return;
    }
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/notifications/add",
            action.payload,
            config
        );

        if (response.data.result && response.data.result.success) {
            yield put(systemActions.systemNotification(response.data.result.message));
        }

    } catch (error) {

    }
}

export function* clearNotificationsSaga(action){
    if (!action.token) {
        return;
    }
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/notifications/clear",
            config
        );
        yield put(actions.clearNotificationsDone(response.data.result));

    } catch (error) {

    }
}