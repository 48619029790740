export const PAYMENT_LOAD = 'PAYMENT_LOAD';
export const PAYMENT_LOAD_FINISH = 'PAYMENT_LOAD_FINISH';

export const paymentLoad = (token, start, end) => {
    return {
        type: PAYMENT_LOAD,
        token: token,
        start: start,
        end: end
    }
}

export const paymentLoadFinish = (payload) => {
    return {
        type: PAYMENT_LOAD_FINISH,
        payload: payload
    }
};