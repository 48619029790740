export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const AUTH_CHECK_STATE = "AUTH_CHECK_STATE";
export const CHECK_AUTH_TIMEOUT = "CHECK_AUTH_TIMEOUT";

export const AUTH_INITIATE_LOGOUT = "AUTH_INITIATE_LOGOUT";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const REGISTER = "REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";

export const RECOVER_PASSWORD = "RECOVER_PASSWORD";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_FAILED = "RECOVER_PASSWORD_FAILED";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const checkAuthTimeout = (number) =>  {
    return {
        type: CHECK_AUTH_TIMEOUT,
        expirationTime: number
    };
};

export const login = (payload) => {
    return {
        type: LOGIN,
        email: payload.email,
        password: payload.password
    };
};

export const logout = () => {
    return {
        type: AUTH_INITIATE_LOGOUT
    };
};

export const logoutSucceed = () => {
    return {
        type: AUTH_LOGOUT
    };
};


export const loginSuccess = (payload) => {
    return {
        type: LOGIN_SUCCESS,
        payload: payload
    }
};

export const loginFailed = (error) => {
    return {
        type: LOGIN_FAILED,
        payload: error
    }
};

export const authCheckState = () => {
    return {
        type: AUTH_CHECK_STATE
    };
};

export const register = (payload) => {
    return {
        type: REGISTER,
        email: payload.email,
        password: payload.password,
        password_confirmation: payload.password_confirmation,
        promo: payload.promo,
        telegram: payload.telegram
    }
}

export const registerSuccess = (payload) => {
    return {
        type: REGISTER_SUCCESS,
        payload: payload
    }
};

export const registerFailed = (error) => {
    return {
        type: REGISTER_FAILED,
        payload: error
    }
};

export const recoverPassword = (payload) => {
    return {
        type: RECOVER_PASSWORD,
        email: payload.email
    }
};

export const recoverPasswordSuccess = (payload) => {
    return {
        type: RECOVER_PASSWORD_SUCCESS,
        payload: payload
    }
};

export const recoverPasswordFailed = (error) => {
    return {
        type: RECOVER_PASSWORD_FAILED,
        payload: error
    }
};

export const resetPassword = (payload) => {
    return {
        type: RESET_PASSWORD,
        token: payload.token,
        email: payload.email,
        password: payload.password,
        password_confirmation: payload.password_confirmation,
    }
};

export const resetPasswordSuccess = (payload) => {
    return {
        type: RESET_PASSWORD_SUCCESS,
        payload: payload
    }
};

export const resetPasswordFailed = (error) => {
    return {
        type: RESET_PASSWORD_FAILED,
        payload: error
    }
};