import {put} from "@redux-saga/core/effects";
import * as actions from "./teamUsersActions";
import axios from "axios";

export function* teamUsersLoadSaga(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/teamUsers/getUsers",
            config
        );
        yield put(actions.teamUsersLoadFinish(response.data.result));

    } catch (error) {

    }
}

export function* addTeamUserSaga(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }

    try {
        const response = yield axios.post(
            "/v1/teamUsers/addTeamUser",
            action.payload,
            config
        );
        if (response.data.result.success) {
            yield put(actions.teamUserAddSuccess(response.data.result));
        } else {
            yield put(actions.teamUserAddFail(response.data.result.message.errors));
        }
    } catch (error) {

    }
}

export function* updateTeamUserSaga(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }

    try {
        const response = yield axios.post(
            "/v1/teamUsers/updateTeamUser",
            action.payload,
            config
        );
        if (response.data.result.success) {
            yield put(actions.teamUserUpdateSuccess(response.data.result));
        } else {
            yield put(actions.teamUserUpdateFail(response.data.result.message.errors));
        }
    } catch (error) {

    }
}

export function* deleteTeamUserSaga(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }

    try {
        const response = yield axios.post(
            "/v1/teamUsers/deleteTeamUser",
            action.payload,
            config
        );
        if (response.data.result.success) {
            yield put(actions.teamUserDeleteSuccess(response.data.result));
        } else {
            yield put(actions.teamUserDeleteFail(response.data.result.message.errors));
        }
    } catch (error) {

    }
}